import React, { Fragment } from 'react'

import { ImageBreakpoints, ImageData } from '../../types/Image'
import { acceptableFileTypesForWebp } from '../../utils/fileTypesForWebP'
import gridTheme from '../../utils/gridTheme'

const breakpoints = gridTheme.breakpoints

/**
 * Checks if the image URL is from the local backend, if so it is not going
 * to get optimised by the CDN.
 */
function hasOptimisedAsset(imageData?: ImageData) {
  return (
    imageData?.subtype &&
    acceptableFileTypesForWebp.test(imageData?.subtype) &&
    !imageData?.url?.includes('local-backend.moneyfarm.com')
  )
}

function getActuallySpecifiedBreakpoints(
  breakpointsImageData: BreakpointsImagesData
) {
  return Object.values(breakpointsImageData).filter(
    imageDataAtSize => !!imageDataAtSize?.id
  )
}

function hasOnlyOneBreakpoint(breakpointsImageData: BreakpointsImagesData) {
  return getActuallySpecifiedBreakpoints(breakpointsImageData).length === 1
}

function onlySpecifiedBreakpoint(breakpointsImageData: BreakpointsImagesData) {
  return getActuallySpecifiedBreakpoints(breakpointsImageData)[0]
}

export type BreakpointsImagesData = {
  xs: ImageData
  sm?: ImageData
  md?: ImageData
  lg?: ImageData
  xl?: ImageData
}

function multipleImagesSources(breakpointsImageData: BreakpointsImagesData) {
  return Object.keys(breakpointsImageData).map((breakpoint, index) => {
    const typesafeBreakpoint = breakpoint as ImageBreakpoints

    if (breakpointsImageData[typesafeBreakpoint]) {
      const imageData = breakpointsImageData[typesafeBreakpoint]
      const imageUrl = imageData?.url
      const mediaQuery = `(min-width: ${breakpoints[typesafeBreakpoint]}px)`

      return (
        <Fragment key={index}>
          {hasOptimisedAsset(imageData) && (
            <source
              media={mediaQuery}
              srcSet={`${imageUrl}.webp`}
              type="image/webp"
            />
          )}

          <source media={mediaQuery} srcSet={imageUrl} type={imageData?.mime} />
        </Fragment>
      )
    }
  })
}

function singleImageSources(imageData: ImageData) {
  if (imageData.mime === 'image/svg+xml') return null

  return (
    <Fragment>
      {Object.entries(imageData.sizes)
        .reverse()
        .filter(([sizeName]) => sizeName !== 'thumbnail')
        .map(([sizeName, { width, url }]) => (
          <Fragment key={sizeName}>
            {hasOptimisedAsset(imageData) && (
              <source
                media={`(min-width: ${width}px)`}
                srcSet={`${url}.webp`}
                type="image/webp"
              />
            )}

            <source
              media={`(min-width: ${width}px)`}
              srcSet={url}
              type={imageData?.mime}
            />
          </Fragment>
        ))}
    </Fragment>
  )
}
/**
 * This function returns a list of sources for a `<picture>` element, given a
 * record of image data associated to specific breakpoints. If image data is
 * only specified for a single breakpoint, then the sources are generated in a
 * progressive loading fashion.
 */
export function sources(breakpointsImageData: BreakpointsImagesData) {
  // If actually different images are specified for different breakpoints.
  if (!hasOnlyOneBreakpoint(breakpointsImageData))
    return multipleImagesSources(breakpointsImageData)

  // Otherwise, generate a list of sources using progressively bigger images for
  // the only breakpoint specified.
  return singleImageSources(
    onlySpecifiedBreakpoint(breakpointsImageData) as ImageData
  )
}
